import React, { useEffect, useState } from 'react'
import { Table, Space, Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { ModalForm } from './Modals/UserModal';
import UsersService from '../Services/UsersService'
import UserCard from './Modals/UserCard';
import Cookies from 'js-cookie';

const Users = () => {

  const [loading, setLoading] = useState(false)

  const [users, setUsers] = useState([]);

  const [loadingCard, setLoadingCard] = useState(false);

  const [user, setUser] = useState({})

  const [open, setOpen] = useState(false);

  const [modeModal, setModeModal] = useState('create')

  const showUserModal = () => {
    setOpen(true);
  };

  const hideUserModal = () => {
    setOpen(false);
  };

  const [openCard, setOpenCard] = useState(false);

  const showUserCard = () => {
    setOpenCard(true);
  };

  const hideUserCard = () => {
    setOpenCard(false);
  };

  const storedToken = Cookies.get('accessToken');

  useEffect(() => {

    if (storedToken) {
      UsersService.getUsers(storedToken, setLoading, setUsers)
    }
  }, [storedToken]);


  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email пользователя',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => (<span>{dayjs(value).format('DD.MM.YYYY')}</span>)
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: value => (<span>{dayjs(value).format('DD.MM.YYYY')}</span>)
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      render: (_, record) => (
        <Space size='small'>

          <div>
            <Tooltip title="Редактировать" placement="bottom">

              <EditOutlined
                style={{ color: '#1890ff', fontSize: '15px', cursor: 'pointer' }}
                onClick={() => {
                  setModeModal('edit')
                  showUserModal()
                  UsersService.showUser(storedToken, record.id, setLoadingCard, setUser)
                }}
              />

            </Tooltip>

          </div>
          <div>
            <Tooltip title="Карточка" placement="bottom">

              <FileSearchOutlined
                onClick={() => {
                  showUserCard()
                  UsersService.showUser(storedToken, record.id, setLoadingCard, setUser)
                }}
                style={{ color: '#1560bd', fontSize: '15px', cursor: 'pointer' }}
              />

            </Tooltip>
          </div>

          <Popconfirm
            title="Вы уверены?"
            onConfirm={() => UsersService.deleteUser(storedToken, record.id, setLoading, setUsers)
            }
            okButtonProps={{
              loading: loading,
            }}
            okText="Удалить"
            cancelText="Отмена">
            <div>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined
                  style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>

      <Button
        htmlType="button"
        onClick={() => {
          setModeModal('create')
          showUserModal()
        }}
      >
        Добавить пользователя
      </Button>

      <Table
        dataSource={users.map(item => ({ ...item, key: item.id }))}
        columns={columns}
        style={{ marginTop: 8 }}
        loading={loading}
        scroll={{
          x: 768,
        }}
      />

      <ModalForm open={open} onCancel={hideUserModal} loadingCard={loadingCard} setUsers={setUsers} mode={modeModal} user={user} />

      <UserCard open={openCard} onCancel={hideUserCard} loading={loadingCard} user={user} />

    </div>
  )
}

export default Users