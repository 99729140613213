import React, { useEffect, useState } from 'react'
import { Tag, Flex, Typography, Button } from 'antd';
import {
  useParams,
  useNavigate,
  Link
} from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import PublicSectionsService from '../Services/PublicSectionsService';
import { replaceTags } from '../../Admin App/Services/replaceTags';

const ArticlePage = () => {

  let { slug } = useParams()

  const navigate = useNavigate();

  const [article, setArticle] = useState({})

  const [loadingArticles, setLoadingArticle] = useState(false)


  useEffect(() => {

    PublicSectionsService.getPublicArticle(slug, setLoadingArticle, setArticle)

  }, [slug]);

  const presetColors = ['lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

  const tagTranslations = {
    'home': 'Главная',
    'internet': 'Интернет',
    'cctv': 'Видеонаблюдение',
    'tv': 'Телевидение',
    'otv-snz': 'ОТВ-Снежинск',
    'article': 'Статья'
  };

  // const getRandomColor = () => {
  //   const randomIndex = Math.floor(Math.random() * presetColors.length);
  //   return presetColors[randomIndex];
  // };

  const getColor = (item) => {
    if (item.tag === 'home') return 'lime';
    if (item.tag === 'internet') return 'blue';
    if (item.tag === 'tv') return 'purple';
    if (item.tag === 'cctv') return 'geekblue';
    if (item.tag === 'article') return 'cyan';
    return 'blue'
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>

      <style>
        {`
	
        .main .prlx-l.bg-img { height: 150vh !important; }
   
                `}
      </style>
      <section className='s-article-nav'>
        <div className='container article-container' >

          <LeftOutlined onClick={handleBack} style={{ marginTop: '11rem', fontSize: 50, color: 'white' }} />

        </div>

      </section>

      <section className={article?.sections?.data?.some(item => item?.tag === 'article') ? 's-2 article22' : 's-2'}>
        <div className='container article-container' >
          <div className="article" style={{ paddingTop: '4em', paddingBottom: '4em' }}>
            <h3 className="title">{article?.title}</h3>
            <div>
              {
                article?.sections?.data?.map((item, index) => (
                  <Tag
                    key={index}
                    color={getColor(item)}
                    bordered={false}
                    style={{
                      display: 'inline-block',
                      marginTop: 4,
                      marginBottom: 4
                    }}
                  >
                    {tagTranslations[item?.tag] || item?.tag}
                  </Tag>
                ))
              }
            </div>

            {article && article.text ? <div style={{ paddingTop: '2em' }} dangerouslySetInnerHTML={{ __html: replaceTags(article.text, article.images.data) }} /> : null}

          </div>
        </div>
      </section>

      <div className="s-footer">
        <div className="container" style={{ zIndex: 2 }}>
          <div className="flex">
            <Flex
              wrap='wrap'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 64,
                borderTop: '1px solid rgba(255,255,255, 0.2)',
              }}>
              <Typography style={{ color: 'white' }}>{new Date().getFullYear()} «Вега-интернет»</Typography>

              <Typography style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/articles/rekvizity-i-dokumenty')}>
                Реквизиты и документы
              </Typography>

            </Flex>
          </div>
        </div>

      </div>
    </>
  )
}

export default ArticlePage