import formData from '../Helpers/render-schema.json'
import React from 'react';
import { Form, Input } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import FileSelector from '../Components/FileSelector';

const { TextArea } = Input;


export const renderSelectedComponents = (selectedSections, setSelectedComponents) => {

    let components = [];

    selectedSections.forEach((section) => {
        const [sectionName] = section.split(' ');
        const sectionFields = getFieldsForSection(sectionName);

        if (sectionFields) {
            components = components.concat(sectionFields);
        }
    });

    // Группируем поля по slug
    const groupedComponents = components.reduce((acc, field) => {
        if (!acc[field.slug]) {
            acc[field.slug] = [];
        }
        acc[field.slug].push(field);
        return acc;
    }, {});

    // Выбираем поля с приоритетом для обязательных полей
    const uniqueComponents = Object.values(groupedComponents).map(fields => {
        const requiredField = fields.find(field => field.required);
        return requiredField || fields[0];
    });

    setSelectedComponents(uniqueComponents);
};

export const getFieldsForSection = (section) => {
    // Проверяем наличие секции в данных
    if (formData[section]) {
        return formData[section];
    } else {
        // Если секция не найдена, возвращаем пустой массив
        return null;
    }
};


export const makeField = (field, editorRef, textValue, setTextValue, storedToken, openDrawer, article, handleFileChange, setFiles, files) => {
    switch (field.type) {
        case 'title':
            return (
                <Form.Item
                    key={field.slug}
                    name={field.name}
                    label={field.label}
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: field.required,
                            message: 'Пожалуйста, ведите Заголовок!',
                        },
                    ]}>
                    <Input allowClear />
                </Form.Item>
            );
        case 'subtitle':
            return (
                <Form.Item
                    key={field.slug}
                    name={field.name}
                    label={field.label}
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: field.required,
                            message: 'Пожалуйста, ведите Подзаголовок!',
                        },
                    ]}
                >

                    <TextArea allowClear />
                </Form.Item>
            );
        case 'editor':
            return (
                <Form.Item
                    key={field.slug}
                    label={field.label}
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: field.required,
                            message: 'Пожалуйста, ведите Текст!',
                        },
                    ]}
                >
                    <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={textValue.toString()}
                        // initialValue={textValue.toString()}
                        onEditorChange={(newValue, editor) => {
                            setTextValue(newValue.toString())
                        }}
                        init={{
                            height: 500,
                            menubar: true,
                            promotion: false,
                            branding: false,
                            license_key: 'gpl',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help | customInsertButton',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            language: 'ru',
                            language_url: '/tinymce/langs/ru.js',
                        }}
                    />
                </Form.Item>
            );
        case 'image':
            return (
                <Form.Item
                    key={field.slug}
                    name={field.name}
                    label={field.label}
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: field.required,
                            message: 'Пожалуйста, выберите Изображение!',
                        },
                    ]}
                >
                    <FileSelector
                        token={storedToken}
                        openDrawer={openDrawer}
                        imageId={article?.image?.id}
                        onChange={handleFileChange}
                        setFiles={setFiles}
                        files={files}
                    />
                </Form.Item>
            );
        case 'url':
            return (
                <Form.Item
                    key={field.slug}
                    name={field.name}
                    label={field.label}
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: field.required,
                            message: 'Пожалуйста, введите Ссылку!',
                        },
                    ]}
                >
                    <Input allowClear />
                </Form.Item>
            );
        case 'images':
            return (
                <Form.Item
                    key={field.slug}
                    name={field.name}
                    label={field.label}
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: field.required,
                            message: 'Пожалуйста, укажите Изображения!',
                        },
                    ]}
                    style={{ display: 'none' }}
                >
                    <Input allowClear />
                </Form.Item>
            );
        default:
            return null;
    }
};