import { message } from 'antd';
import CONFIG from '../../config';

const filesApi = CONFIG.FILES_API

const FilesService = {

    getFiles: (token, setLoading, setFiles, setPagination, page = 1, perPage = 20) => {
        setLoading(true);
        fetch(`${filesApi}?page=${page}&per_page=${perPage}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при получении списка файлов');
                }
                return response.json();
            })
            .then(data => {
                const formattedFileList = data.data.map(item => {

                    return ({
                        uid: item.id,
                        name: `${item.name}`,
                        url: item.url,
                        thumbUrl: item.url,
                    })
                });
                setFiles(formattedFileList);
                setPagination({
                    current: data.meta.pagination.current_page,
                    pageSize: data.meta.pagination.per_page,
                    total: data.meta.pagination.total,
                });
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    createFile: (token, formData, setLoading, setFiles, onSuccess, onError, file, setPagination, page = 1, perPage = 20) => {
        setLoading(true);

        fetch(`${filesApi}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка загрузки файла');
                }
                return response.json();
            })
            .then(data => {
                onSuccess(data, file);
                message.success(`Новый файл успешно добавлен!`);
                FilesService.getFiles(token, setLoading, setFiles, setPagination, page = 1, perPage = 20);
            })
            .catch(error => {
                message.error(error.message);
                onError(error, file);
            })
            .finally(() => {

                setLoading(false)
            });
    },

    deleteFile: (token, id, setLoading, setFiles, setPagination, page = 1, perPage = 20) => {
        setLoading(true);
        fetch(`${filesApi}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при удалении файла');
                }

                return response;
            })
            .then(() => {
                message.success(`Файл успешно удалён!`);
                FilesService.getFiles(token, setLoading, setFiles, setPagination, page = 1, perPage = 20);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => {
                setLoading(false)
            });
    },

};

export default FilesService;