import { message } from 'antd';
import CONFIG from '../../config';

const sectionsApi = CONFIG.SECTIONS_API

const SectionsService = {

    getSections: (token, setLoading, setSections) => {
        setLoading(true);
        fetch(`${sectionsApi}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при получении списка секций');
                }
                return response.json();
            })
            .then(data => {
                setSections(data.data);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    updateSections: (token, id, values, setLoading, setUsers, onCancel) => {
        setLoading(true);
        fetch(`${sectionsApi}/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({ ...values, '_method': 'put' })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при редактировании секции');
                }
                return response.json();
            })
            .then(data => {
                message.success(`Секция успешно отредактирована!`);
                SectionsService.getSections(token, setLoading, setUsers);
                onCancel()
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    showSection: (token, id, setLoadingCard, setUser) => {
        setLoadingCard(true);
        fetch(`${sectionsApi}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка загрузки секции');
                }
                return response.json();
            })
            .then(data => {
                setUser(data)
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoadingCard(false));
    },

    deleteSection: (token, id, setLoading, setUsers) => {
        setLoading(true);
        fetch(`${sectionsApi}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при удалении пользователя');
                }

                return response;
            })
            .then(() => {
                message.success(`Пользователь успешно удалён!`);
                SectionsService.getSections(token, setLoading, setUsers);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

};

export default SectionsService;