import React from 'react';
import { Flex, Typography, List, Carousel, Tag, Segmented } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../table.css'
import '../carousel.css'
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import {
    Link,
} from "react-router-dom";

const SectionRenderer = (sections, navigate) => {
    return (
        <>
            {
                sections.map(section => {
                    switch (section.type) {
                        case 'news':
                            return <NewsSection key={section.id} {...section} />;
                        case 'rubrics':
                            return <RubricsSection key={section.id} {...section} />;
                        case 'rates':
                            return <RatesSection key={section.id} navigate={navigate} {...section} />;
                        // case 'services':
                        //     return <ServiceSection key={section.id} {...section} />;
                        // case 'information_portal':
                        //     return <InformationPortalSection key={section.id} {...section} />;
                        case 'carousel':
                            return <CarouselSection key={section.id} {...section} />;
                        case 'text':
                            const showFooter = section.title === '' || section.title === null;
                            return <TextSection key={section.id} navigate={navigate} showFooter={showFooter}  {...section} />;
                        default:
                            return null;
                    }
                })
            }
        </>

    );

};

const NewsSection = ({ title, text, articles, tag }) => {

    const presetColors = ['lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

    const filterTags = (tags) => {

        const filteredTags = tags.filter((item) => item.type === 'news');
        if (tag) return filteredTags.filter((item) => item.tag !== tag && item.tag !== 'home');
        return filteredTags

    };

    const tagTranslations = {
        'home': 'Главная',
        'internet': 'Интернет',
        'cctv': 'Видеонаблюдение',
        'tv': 'Телевидение',
        'otv-snz': 'ОТВ-Снежинск',
        'article': 'Статья'
    };

    const sortedArticles = [...articles.data].sort((a, b) => new Date(b.published_at) - new Date(a.published_at));

    const setNewsClass = (tag) => {

        if (tag === 'home') return 'news';
        if (tag === 'internet') return 'news news-internet';
        if (tag === 'tv') return 'news news-tv';
        if (tag === 'cctv') return 'news news-cctv';
        return 'news'

    }


    return (
        <section className="s-2 white-circle">
            <div className="container">
                <div className={setNewsClass(tag)}>
                    <h2 className="title">{title}<span>{title}</span></h2>

                    <List
                        pagination={{
                            hideOnSinglePage: true
                        }}
                        dataSource={sortedArticles}
                        renderItem={(item) => {

                            const filteredTags = filterTags(item.sections.data)

                            // const getRandomColor = () => {
                            //     const randomIndex = Math.floor(Math.random() * presetColors.length);
                            //     return presetColors[randomIndex];
                            // };

                            const getColor = (item) => {
                                if (item.tag === 'home') return 'lime';
                                if (item.tag === 'internet') return 'blue';
                                if (item.tag === 'tv') return 'purple';
                                if (item.tag === 'cctv') return 'geekblue';
                                if (item.tag === 'article') return 'cyan';
                                return 'blue'
                            };

                            return item.url
                                ? <>
                                    <a className="news_block" href={item.url}>
                                        <div className="head">
                                            <h3>{item.title}</h3>
                                            <div className="left">
                                                <div className="date">{dayjs(item.published_at).format('DD.MM.YYYY')}</div>
                                                {filteredTags.map((item, index) => (
                                                    <div className="tags" key={index}>
                                                        <Tag key={index}
                                                            color={getColor(item)}
                                                            bordered={false}
                                                            style={{ marginTop: 4, marginBottom: 4 }}>
                                                            {tagTranslations[item.tag] || item.tag}
                                                        </Tag>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div className="text" dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                                    </a>
                                </>

                                : <>
                                    <div className="news_block">
                                        <div className="head">
                                            <h3>{item.title}</h3>
                                            <div className="left">
                                                <div className="date">{dayjs(item.published_at).format('DD.MM.YYYY')}</div>
                                                {filteredTags.map((item, index) => (
                                                    <div className="tags" key={index}>
                                                        <Tag key={index}
                                                            color={getColor(item)}
                                                            bordered={false}
                                                            style={{ marginTop: 4, marginBottom: 4 }}>
                                                            {tagTranslations[item.tag] || item.tag}
                                                        </Tag>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="text">
                                            {item.subtitle}
                                        </div>
                                    </div>

                                </>
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

const RubricsSection = ({ title, text, articles, tag }) => {

    const sortedArticles = [...articles.data].sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

    return (
        <section className={tag === 'otv' ? "photo-bottom s-2 white-circle" : "s-2 white-circle"}>
            <div className="container">
                <div className="rubrics-news">
                    <h2 className="title">{title}<span>{title}</span></h2>

                    <List
                        pagination={{
                            hideOnSinglePage: true
                        }}
                        dataSource={sortedArticles}
                        renderItem={(item) => {

                            return item.url
                                ? <>
                                    <a className="rubrics-news_block" href={item.url}>
                                        <div className="head">
                                            <h3>{item.title}</h3>
                                            {/* {parse(item.subtitle)} */}
                                            <div className="text" dangerouslySetInnerHTML={{ __html: item.subtitle }} />


                                        </div>

                                    </a>
                                </>

                                : <>
                                    <div className="rubrics-news_block">
                                        <div className="head">
                                            <h3>{item.title}</h3>
                                            {/* {parse(item.subtitle)} */}
                                            <div className="text" dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                                        </div>
                                    </div>
                                </>

                        }}
                    />
                </div>
            </div>
        </section>
    );
};



const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <LeftOutlined
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? "slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
    >
    </LeftOutlined>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <RightOutlined
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? "slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
    </RightOutlined>
);

const settingsFirstCarousel = {
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    slidesToShow: 3,
    slidesToScroll: 3,
};

const settingsSecondCarousel = {
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
};

const ServiceSection = ({ title, type, text, articles }) => {

    const sortedArticles = [...articles.data].sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

    return (
        <section className="s-3 blue">

            <div className="container">

                <h2 className="title">{title}<span>{title}</span></h2>

                <Carousel
                    className='carousel'
                    arrows={true}
                    infinite={false}
                    slidesToShow={3}
                    // variableWidth={true}
                    responsive={[
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 550,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}
                    {...settingsFirstCarousel}
                >

                    {
                        sortedArticles.map((item, index) => {

                            return (

                                <div key={index} >
                                    <div className="c-card" >
                                        <div className="bg">
                                            <div className="img"
                                                style={{ backgroundImage: `url(${item?.image?.url})` }}
                                            >
                                                {/* <img src={item?.image?.url} /> */}
                                            </div>
                                            <div className="title text-shadow">{item?.title}</div>
                                            <hr />
                                            <div className="text">{item?.subtitle ? parse(item?.subtitle) : null}</div>
                                            <a className="btn btn-gradient" href={item?.url}>Подробнее</a>
                                        </div>

                                    </div>
                                </div>

                            )
                        })
                    }

                </Carousel>
            </div >
        </section >
    );
};


const RatesSection = ({ navigate, title, type, text, articles, tag, url, url_alias }) => {

    const sortedArticles = [...articles.data].sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

    return (

        <section className="s-3 rates blue">
            <div className="container">
                <h2 className="title">{title}<span>{title}</span></h2>

                <div className='segmented-container'>
                    <Segmented
                        className='rates-segemented blue'
                        defaultValue={`/${tag}`}
                        options={
                            [{
                                label: 'Интернет',
                                value: '/internet'
                            },
                            {
                                label: 'Телевидение',
                                value: '/tv'
                            },
                            {
                                label: 'Видеонаблюдение',
                                value: '/cctv'
                            }]
                        }
                        onChange={(value) => {
                            navigate(value);
                        }}
                    />
                    <div className='rates-additional-btns'>

                        {
                            url
                                ? <div href={url}>
                                    <Link to={url}>
                                        <button
                                            className='additional-services-btn'

                                        >
                                            {url_alias}
                                        </button>
                                    </Link>
                                </div>
                                : null
                        }
                        {
                            tag === 'tv'
                                ? <div >
                                    <button
                                        className='additional-services-btn'
                                        onClick={() => navigate('/articles/dopolnitelnye-uslugi-televideniia')}
                                    >
                                        Дополнительные услуги
                                    </button>
                                </div>
                                : null
                        }
                        {
                            tag === 'internet'
                                ? <div>
                                    <button
                                        className='additional-services-btn'
                                        onClick={() => navigate('/articles/dopolnitelnye-uslugi-vs')}
                                    >
                                        Дополнительные услуги
                                    </button>
                                </div>
                                : null
                        }
                    </div>

                </div>

                {
                    tag === 'cctv' ?

                        <div className="v-flex">
                            <div className="w-1-3">
                                <Carousel
                                    className='carousel-rates'
                                    arrows={false}
                                    infinite={false}
                                    variableWidth={false}
                                    slidesToShow={tag === 'tv' ? 3 : 4}
                                    slidesToScroll={tag === 'tv' ? 3 : 4}
                                    responsive={[
                                        {
                                            breakpoint: 1024,
                                            settings: {
                                                slidesToShow: 3,
                                                slidesToScroll: 3,
                                            }
                                        },
                                        {
                                            breakpoint: 720,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 2,
                                            }
                                        },
                                        {
                                            breakpoint: 480,
                                            settings: {
                                                slidesToShow: 1,
                                                slidesToScroll: 1,
                                            }
                                        }
                                    ]}
                                    {...settingsSecondCarousel}
                                >

                                    {
                                        sortedArticles.map((item, index) => {

                                            return (
                                                <div key={index} >
                                                    <div className="c-card" >
                                                        <div className="bg">
                                                            <div className="img"
                                                                style={{ backgroundImage: `url(${item?.image?.url})` }}
                                                            >
                                                                {/* <img src={item?.image?.url} /> */}
                                                            </div>
                                                            <div className="title text-shadow">{item?.title}</div>
                                                            <div className="text">{item?.subtitle ? parse(item?.subtitle) : null}</div>
                                                            {/* <a className="btn btn-gradient" href={item?.url}>Подробнее</a> */}
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>
                            </div>

                            <div className="w-1-2">
                                <div className="article">{text ? parse(text) : null}</div>

                            </div>
                        </div>

                        : <Carousel
                            className='carousel-rates'
                            arrows={true}
                            infinite={false}
                            variableWidth={false}
                            slidesToShow={tag === 'tv' ? 3 : 4}
                            slidesToScroll={tag === 'tv' ? 3 : 4}
                            responsive={[
                                {
                                    breakpoint: 1600,
                                    settings: {
                                        slidesToShow: tag === 'tv' ? 3 : 4,
                                        slidesToScroll: tag === 'tv' ? 3 : 4,
                                    }
                                },
                                {
                                    breakpoint: 1300,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                    }
                                },
                                {
                                    breakpoint: 1000,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                    }
                                },
                                {
                                    breakpoint: 650,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    }
                                }
                            ]}
                            {...settingsSecondCarousel}
                        >

                            {
                                sortedArticles.map((item, index) => {

                                    return (
                                        <div key={index} >
                                            <div className="c-card" >
                                                <div className="bg">
                                                    <div className="img"
                                                        style={{ backgroundImage: `url(${item?.image?.url})` }}
                                                    >
                                                        {/* <img src={item?.image?.url} /> */}
                                                    </div>
                                                    <div className="title text-shadow">{item?.title}</div>
                                                    <div className="text">{item?.subtitle ? parse(item?.subtitle) : null}</div>
                                                    {/* <a className="btn btn-gradient" href={item?.url}>Подробнее</a> */}
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                }

            </div >
        </section >

    );
};


const InformationPortalSection = ({ title, type, text, articles }) => {

    const sortedArticles = [...articles.data].sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

    return (
        <section className="s-4">
            <div className="container">

                <h2 className="title">{title}<span>{title}</span></h2>

                <div className="info-links">
                    {
                        sortedArticles.map((item, index) => {
                            return <div key={item.id}> <a href={item.url} style={{ backgroundImage: `url(${item.image.url})` }}>{item.title}</a></div>
                        })
                    }
                </div>

            </div>
        </section>
    );
};

const CarouselSection = ({ title, type, text, articles }) => {

    const sortedArticles = [...articles.data].sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

    return (
        <section className="s-5">
            <Carousel
                autoplay
                autoplaySpeed={10000}
                arrows={true}
                {...settingsSecondCarousel}>

                {
                    sortedArticles.map((item, index) => {
                        return <div key={index} className="banners">
                            <a href={item?.url} className="banner">
                                <img src={item?.image?.url} width="100%" />
                            </a>
                        </div>
                    })
                }

            </Carousel>
        </section>
    );
};

const TextSection = ({ navigate, title, type, articles, tag, showFooter }) => {

    const sortedArticles = [...articles.data].sort((a, b) => new Date(a.published_at) - new Date(b.published_at));

    function styleTableInText(text) {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = text;

        var table = tempDiv.querySelector('table');

        if (table) {
            // Создаем новый div для обертки таблицы
            var wrapperDiv = document.createElement('div');
            wrapperDiv.classList.add('table-wrapper');

            // Добавляем класс для таблицы
            table.classList.add('styled-table');

            // Вставляем таблицу в обертку
            table.parentNode.insertBefore(wrapperDiv, table);
            wrapperDiv.appendChild(table);

            var styledTableHTML = tempDiv.innerHTML;

            return styledTableHTML;
        }

        return text;
    }

    return (

        <>
            <section className={showFooter ? "s-3 blue" : "s-3 services blue"}>
                <div className="container">
                    {
                        sortedArticles.map((item, index) => {
                            return (
                                <div key={`${item.id}`} className="article">
                                    <h2 className="title">{item.title}<span>{item.title}</span></h2>
                                    {item.text ? <div dangerouslySetInnerHTML={{ __html: styleTableInText(item.text) }} /> : null}
                                </div>
                            );
                        })
                    }
                </div>
            </section>

            {showFooter && (
                <div className="s-footer">
                    <div className="container" style={{ zIndex: 2 }}>
                        <div className="flex">
                            <Flex
                                wrap='wrap'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    height: 64,
                                    borderTop: '1px solid rgba(255,255,255, 0.2)',
                                }}>
                                <Typography style={{ color: 'white' }}>{new Date().getFullYear()} «Вега-интернет»</Typography>

                                <Typography style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/articles/rekvizity-i-dokumenty')}>
                                    Реквизиты и документы
                                </Typography>

                            </Flex>
                        </div>
                    </div>
                    <div className="footer-bg f-circle"></div>
                    <div className="footer-bg f-mountains"></div>
                    <div className="footer-bg f-cloud"></div>
                    <div className="footer-bg f-stars"></div>
                </div>
            )}


        </>

    )
};

export default SectionRenderer;
