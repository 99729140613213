import React, { useState, useEffect } from 'react'
import { Carousel, List, Button, Col, Row, Card, Flex, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import logo2 from '../../../Assets/img/logo white.svg'
import PublicSectionsService from '../Services/PublicSectionsService';
import dayjs from 'dayjs';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import SectionRenderer from '../Services/SectionRenderer';


const Home = () => {

  const [sections, setSections] = useState([])

  const [loadingSections, setLoadingSections] = useState(false)

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {

    const tag = location.pathname.slice(1)

    PublicSectionsService.getPublicSections(tag, setLoadingSections, setSections)

  }, []);

  return (
    <>
      <section className="s-1">
        <div className="container">
          <div className="logo">
            <img src={logo2} />
          </div>
          <div className="buttons">
            <a className="btn btn-welcome-1" href="https://lk.vega-int.ru/pubexecuter?module=admin&action=Login">Личный кабинет</a>
            <a className="btn btn-welcome-2" href="https://otvnewspaper.vega-int.ru/">Подать объявление</a>
            <a className="btn btn-welcome-3" href="https://vp.ru/providers/vegaservice/">Оплатить</a>
          </div>
        </div>
      </section>

      <div className='s-1-hands'></div>

      <div className="s-1-hands"></div>

      {SectionRenderer(sections, navigate)}


    </>
  )
}

export default Home