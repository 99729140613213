
import React from 'react'
import { Result, Button } from 'antd';

const NotFound = () => {

    return (

        <section className='s-1 blue'>
            <div className='container' >
                <Result
                    status="404"
                    title="404"
                    subTitle="К сожалению, страница, которую Вы запрашиваете, не существует."
                    extra={
                        <Button
                            type="primary"
                            href={`/`}
                        >
                            На главную
                        </Button>
                    }
                    style={{marginTop: '10em'}}
                />

            </div>

        </section>

    )
}

export default NotFound

