import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme, Result, Button, App as AntdApp } from 'antd';
// import ru_Ru from 'antd/locale'
import ru_RU from "antd/lib/locale/ru_RU"
import { AuthWrapper } from './Pages/Auth/AuthWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <ConfigProvider locale={ru_RU}>
    <AuthWrapper>
      <App />
    </AuthWrapper>
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
