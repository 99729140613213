const CONFIG = {
    AUTH_API: "https://api.web.vega-int.ru/authentication",
    ARTICLES_API: "https://api.web.vega-int.ru/admin/articles",
    FILES_API: "https://api.web.vega-int.ru/admin/files",
    SECTIONS_API: "https://api.web.vega-int.ru/admin/sections",
    USERS_API: "https://api.web.vega-int.ru/users",
    PROFILE_API: "https://api.web.vega-int.ru/profile/me",
    PUBLIC_API: "https://api.web.vega-int.ru",
};

export default CONFIG;