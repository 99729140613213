import { message } from 'antd';
import CONFIG from '../../config';

const publicSectionsApi = CONFIG.PUBLIC_API

const PublicSectionsService = {

    getPublicSections: (tag, setLoadingSections, setSections) => {
        setLoadingSections(true);
        const queryParams = `/sections?tag=${tag}&include=articles`;
        fetch(`${publicSectionsApi}${queryParams}`, {
            method: 'GET',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при получении списка новостей!');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = data.data.filter(item => item.tag === tag);

                setSections(filteredData);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoadingSections(false));
    },

    getPublicArticle: (slug, setLoadingArticle, setArticle) => {
        setLoadingArticle(true);
        const queryParams = `/articles/${slug}`;
        fetch(`${publicSectionsApi}${queryParams}`, {
            method: 'GET',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при получении новости!');
                }
                return response.json();
            })
            .then(data => {
                setArticle(data);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoadingArticle(false));
    },


};

export default PublicSectionsService;