import { message } from 'antd';
import { createContext, useContext, useState } from "react";
import Cookies from 'js-cookie';
import CONFIG from '../config';

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = ({ children }) => {

    const [user, setUser] = useState({ email: "", isAuthenticated: false });
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false)

    const login = (email, password, rememberMe) => {

        return new Promise((resolve, reject) => {
            setLoading(true);
            // Делаем запрос на сервер для аутентификации
            fetch(CONFIG.AUTH_API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({ grant_type: 'password', email: email, password: password }),
            })
                .then(response => {

                    if (!response.ok) {
                        throw new Error('Неверный пароль или имя пользователя');
                    }
                    return response.json();
                })
                .then(data => {

                    const authToken = data.access_token;

                    setUser({ email: email, isAuthenticated: true });
                    if (rememberMe) {
                        Cookies.set('accessToken', authToken, { expires: 30 })
                        // localStorage.setItem('authToken', authToken); // Сохраняем токен в localStorage

                    }
                    setToken(authToken);
                    message.success("Вход выполнен успешно!");
                    resolve(); // Опционально, т.к. нет передачи дополнительной информации
                })
                .catch(error => {
                    message.error(error.message);
                    reject(error.message); // Отправляем сообщение об ошибке в случае неудачи
                })
                .finally(() => setLoading(false));
        });
    };

    const logout = () => {
        setUser({ email: "", isAuthenticated: false });
        setToken("");
        Cookies.remove('accessToken');
        // localStorage.removeItem('authToken');
    };

    return (
        <AuthContext.Provider value={{ user, token, login, logout, loading, setLoading, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};