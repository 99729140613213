import React, { useState } from 'react';
import { Menu, theme, ConfigProvider, Drawer } from 'antd';
import {
    useNavigate,
    useLocation,
    Outlet
} from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../Assets/Images/Shapka/logo_blu.svg'
import './antd-styles.css'
import './style.css'

const MainPage = () => {

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const items = [
        {
            className: 'menu-item',
            key: '/home',
            label: 'Главная',
        },
        {
            className: 'menu-item',
            key: '/internet',
            label: 'Интернет',
        },
        {
            className: 'menu-item',
            key: '/tv',
            label: 'Телевидение',
        },
        {
            className: 'menu-item',
            key: '/cctv',
            label: 'Видеонаблюдение',
        },
        {
            className: 'menu-item',
            key: '/otv',
            label: 'ОТВ-Снежинск',
        },
        // {
        //     className: 'menu-item',
        //     key: '/contacts',
        //     label: 'Контакты',
        // },
    ];

    const navigate = useNavigate();

    const location = useLocation();

    const handleMenuClick = ({ key }) => {
        if (key) {
            navigate(key);
        }
        setVisible(false);
    };


    return (
        <ConfigProvider
            theme={{
                token: {
                    // colorPrimary: "#faad14",
                    fontSize: 16
                },
                components: {
                    // Menu: {
                    //     itemColor: "#FFFFFF",
                    //     itemHoverColor: "#FFFFFF",
                    //     horizontalItemSelectedColor: "#FFFFFF",
                    // }
                },

                algorithm: theme.defaultAlgorithm,
            }}
        >
            <div className='main prlx'>
                <div className='prlx-l prlx-l-4 bg-img'></div>
                <div className='prlx-l prlx-l-3 bg-stars'></div>
                <div className='prlx-l prlx-l-2 bg-mstars'></div>
                <div className='prlx-l prlx-l-1 bg-clouds'></div>
                <nav>

                    <div className='container'>

                        <div
                            className='header'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                            }}
                        >

                            <div style={{ marginRight: '20px', color: '#fff' }}>
                                <a href="/">
                                    <img src={logo} alt='Вега-интернет' style={{ height: 80, width: 150 }} />
                                </a>
                            </div>
                            <MenuOutlined className="menu-icon" onClick={showDrawer} style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }} />
                            <Menu
                                className='hambugger'
                                theme="light"
                                mode="horizontal"
                                defaultSelectedKeys={['1']}
                                selectedKeys={[location.pathname]}
                                items={items}
                                onClick={handleMenuClick}
                                style={{
                                    minWidth: 870,
                                    display: 'flex',
                                    backgroundColor: 'rgb(2 10 59 / 0%)',
                                    listStyle: 'none',
                                    float: 'right',
                                    transition: 'all 0.5s ease-out'
                                }}
                            />
                            <Drawer
                                title="Меню"
                                placement="right"
                                closable
                                onClose={onClose}
                                open={visible}
                            >
                                <Menu
                                    mode="vertical"
                                    defaultSelectedKeys={['1']}
                                    selectedKeys={[location.pathname]}
                                    items={items}
                                    onClick={handleMenuClick}
                                />
                            </Drawer>
                        </div>
                    </div>
                </nav>
                {/* Ломает paralax */}
                {/* <div className='content'> */}
                <Outlet />
                {/* </div> */}

            </div>
        </ConfigProvider >
    );
};

export default MainPage