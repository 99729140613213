import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import FilesService from '../Services/FilesService';// Путь к вашему файловому сервису

const FileSelector = ({ token, openDrawer, imageId, onChange, setFiles, files }) => {
    const [loading, setLoading] = useState(false);
    // const [files, setFiles] = useState([]);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {

        if (imageId) {
            setSelectedFileId(imageId)
        }
        // Получаем список файлов при загрузке компонента
        FilesService.getFiles(token, setLoading, setFiles);
    }, []);

    const handleChange = fileId => {
        setSelectedFileId(fileId);
        onChange(fileId)
    };

    const handleSearch = value => {
        setSearchValue(value);
    };

    const filterOption = (input, option) => {

        const children = option.label.props.children;
        if (children && children.length >= 2) {
            const text = children[1].props.children;
            return text && text.toLowerCase().includes(input.toLowerCase());
        }
        return false;

    }

    return (
        <Select
            placeholder="Выберите файл"
            loading={loading}
            onChange={handleChange}
            value={selectedFileId}
            showSearch
            onSearch={handleSearch}
            filterOption={filterOption}
            options={files.map(file => ({
                value: file.uid,
                label: (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={file.thumbUrl} alt={file.name} style={{ width: '20px', height: '20px', marginRight: '10px', verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}>{file.name}</span>
                    </span>
                ),
            }))}
            allowClear
        />
    );
};

export default FileSelector;
