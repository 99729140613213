import React from 'react'
import { Typography, Space } from 'antd'
import { AuthData } from '../../Auth/AuthWrapper';


const Profile = () => {

  const { user } = AuthData();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>

      <Typography.Title level={4} style={{ marginTop: 0, textAlign: 'center' }}>Профиль</Typography.Title>

      <Space direction='vertical'>

        <Typography.Text>
          Имя пользователя: {user.name}
        </Typography.Text>

        <Typography.Text>
          Email пользователя: {user.email}
        </Typography.Text>

      </Space>
    </div>
  )
}

export default Profile