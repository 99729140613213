import React, { useState, useEffect } from 'react'
import { Drawer, Upload, Skeleton, Button, Popconfirm, Tooltip, Pagination } from 'antd';
import { UploadOutlined, StarOutlined, DeleteOutlined } from '@ant-design/icons'
import FilesService from '../../Services/FilesService';
import Cookies from 'js-cookie';

const FilesDrawer = ({ open, onClose, setFiles, files }) => {

    // const [files, setFiles] = useState([])

    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });

    const handlePageChange = (page, pageSize) => {
        setPagination(prev => ({ ...prev, current: page, pageSize: pageSize }));
    };

    const [loading, setLoading] = useState(false)

    const storedToken = Cookies.get('accessToken');

    const handleCustomRequest = ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('file', file);
        FilesService.createFile(storedToken, formData, setLoading, setFiles, onSuccess, onError, file, setPagination, pagination.current, pagination.pageSize)
    };

    useEffect(() => {

        FilesService.getFiles(storedToken, setLoading, setFiles, setPagination, pagination.current, pagination.pageSize);

    }, [open, storedToken, setFiles, pagination.current, pagination.pageSize]);


    return (
        <Drawer
            title="Файлы"
            placement="right"
            closable={true}
            mask={false}
            onClose={onClose}
            open={open}
            getContainer={false}
            width={'40%'}
            footer={<Button onClick={onClose} style={{ marginTop: 8 }}>Закрыть</Button>}
        >

            {loading ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Skeleton.Image active={true} />
                </div>
            ) : <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Upload.Dragger
                        // className="upload-list-inline"
                        customRequest={handleCustomRequest}
                        listType="picture"
                        fileList={files}
                        showUploadList={{
                            removeIcon: file =>
                                <Popconfirm
                                    title="Вы уверены?"
                                    onConfirm={() => FilesService.deleteFile(storedToken, file.uid, setLoading, setFiles, setPagination, pagination.current, pagination.pageSize)}
                                    okButtonProps={{
                                        loading: loading,
                                    }}
                                    okText="Удалить"
                                    cancelText="Отмена"
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined style={{ fontSize: 18, marginRight: 8 }} />
                            Загрузить
                        </p>
                    </Upload.Dragger>

                    <Pagination
                        style={{ marginTop: 8, alignSelf: 'end' }}
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={handlePageChange}
                    />
                </div>
            </>}
        </Drawer>
    )
}

export default FilesDrawer