import React, { useEffect, useState, useRef } from 'react'
import { Form, Modal, Input, Button, Skeleton } from 'antd';
import SectionsService from '../../Services/SectionsService';
import Cookies from 'js-cookie';
import FileSelector from '../FileSelector';
import FilesDrawer from './FilesDrawer';

const { TextArea } = Input;

const useResetFormOnCloseModal = ({ form, open, mode, section }) => {

    const prevOpenRef = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {

        if (!open && prevOpen) {
            form.resetFields();
        }

        if (open && mode === 'edit') {

            form.setFieldsValue({
                ...section,
                image: section?.image?.id
            });
        }

    }, [form, prevOpen, open, mode]);
};

export const ModalForm = ({ open, onCancel, loadingCard, setSections, mode, section }) => {

    const [form] = Form.useForm();

    const [files, setFiles] = useState([]);

    useResetFormOnCloseModal({
        form,
        open,
        mode,
        section
    });

    const onOk = () => {
        form.submit();
    };

    const [loading, setLoading] = useState(false); // Состояние загрузки

    const [openDrawer, setOpenDrawer] = useState(false);

    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const onClose = () => {
        setOpenDrawer(false);
    };

    // const storedToken = localStorage.getItem('authToken');

    const storedToken = Cookies.get('accessToken');

    const onFinish = (values) => {

        const { title, text, image, url, tag, type, background, url_alias} = values

        const updateValues = {
            'title': title ? title : null,
            'text': text ? text : null,
            'image': image ? image : null,
            'url': url ? url : null,
            'tag': tag ? tag : null,
            'type': type ? type : null,
            'background': background ? background : null,
            'url_alias': url_alias ? url_alias : null,
        };

        if (mode === 'edit') SectionsService.updateSections(storedToken, section.id, updateValues, setLoading, setSections, onCancel)
    }


    return (
        <Modal
            title={mode === 'create' ? "Добавление секции" : 'Редактирование секции'}
            open={open}
            style={{ position: 'relative', overflow: 'hidden' }}
            width={'50vw'}
            footer={[
                <div key="footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={showDrawer}>Файлы</Button>
                    <div>
                        <Button key="back" onClick={onCancel} style={{ marginRight: 8 }}>
                            Закрыть
                        </Button>
                        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
                            ОК
                        </Button>
                    </div>
                </div>
            ]}
            onCancel={onCancel}
        >
            <Skeleton active={loadingCard} loading={loadingCard}>
                <Form form={form} layout="vertical" name="sectionForm" onFinish={onFinish}>
                    <Form.Item
                        name="title"
                        label="Заголовок"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        name="text"
                        label="Текст"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea allowClear />
                    </Form.Item>

                    <Form.Item
                        name="image"
                        label="Изображение"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <FileSelector
                            token={storedToken}
                            openDrawer={openDrawer}
                            setFiles={setFiles}
                            files={files}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Тип"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, введите тип!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input disabled={true} allowClear />
                    </Form.Item>
                    <Form.Item
                        label="Тэг"
                        name="tag"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, ведите тэг!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input disabled={true} allowClear />
                    </Form.Item>

                    <Form.Item
                        name="url"
                        label="Ссылка"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item
                        name="url_alias"
                        label="Название для компонента ссылки"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>

                    <FilesDrawer
                        open={openDrawer}
                        onClose={onClose}
                        setFiles={setFiles}
                        files={files}
                    />

                </Form>
            </Skeleton>
        </Modal >
    );
};

