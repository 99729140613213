import React, { useEffect, useState } from 'react';
import { TeamOutlined, FileTextOutlined, ApartmentOutlined, MenuOutlined } from '@ant-design/icons';
import { Layout, Menu, theme as themeDark, Button, Typography, ConfigProvider, Avatar, Space, Flex, Tooltip, message, Drawer } from 'antd';
import {
    useNavigate,
    useLocation,
    Outlet
} from "react-router-dom";
import { AuthData } from '../Auth/AuthWrapper';
import ProtectedComponent from '../Auth/ProtectedComponent';
import Cookies from 'js-cookie';
import logo from '../.././Assets/Images/Shapka/logo white.svg'
import './style.css'
import CONFIG from '../config';

const AdminPage = () => {

    const navigate = useNavigate();

    const { user, logout, setLoading, setUser, token } = AuthData();

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const location = useLocation();

    const handleMenuClick = ({ key }) => {
        if (key) {
            navigate(key);
        }
        setVisible(false)
    };

    const getUserProfile = (token) => {

        setLoading(true);
        fetch(CONFIG.PROFILE_API, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    navigate("/admin/login")
                    throw new Error('Ошибка при получении профиля пользователя');
                }
                return response.json();
            })
            .then(data => {
                setUser({ email: data.email, name: data.name, isAuthenticated: true });
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    };

    const tokenFromCookie = Cookies.get('accessToken');

    useEffect(() => {
        // console.log('AdminPage mounted');


        if (tokenFromCookie) {
            getUserProfile(tokenFromCookie);
        } else {
            navigate("/admin/login")
        }
    }, []);

    const { Header, Content, Sider } = Layout;

    const items = [
        {
            key: '/admin/users',
            icon: <TeamOutlined />,
            label: 'Пользователи',
        },
        {
            key: '/admin/sections',
            icon: <ApartmentOutlined />,
            label: 'Секции',
        },
        {
            key: '/admin/articles',
            icon: <FileTextOutlined />,
            label: 'Статьи',
        },
    ];

    return (
        <ProtectedComponent>
            <ConfigProvider
                // locale={ru_Ru}
                theme={{
                    token: {
                        colorPrimary: "#faad14",
                    },
                    components: {
                        Menu: {
                            activeBarBorderWidth: 0
                        }
                    },
                    algorithm: themeDark.darkAlgorithm,
                }}
            >
                <Layout
                    style={{ height: '100vh' }}
                >
                    <Sider
                        breakpoint="md"
                        collapsedWidth="0"
                        trigger={null}
                        style={{ background: '#141414' }}
                    >
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <a href="/">
                                <img src={logo} style={{ height: 80, width: 150 }} alt='Вега-интернет' />
                            </a>
                        </div>

                        <Menu
                            theme="light"
                            defaultSelectedKeys={['1']}
                            selectedKeys={[location.pathname]}
                            mode="inline"
                            items={items}
                            onClick={handleMenuClick}
                        />
                    </Sider>
                    <Layout>
                        <Header
                            style={{
                                padding: 0,
                                background: '#141414',
                                margin: '16px 16px 0',
                                borderRadius: 8,
                                fontSize: 16,
                                textAlign: 'center'
                            }}
                        >
                            <Flex align='center' justify='space-between' style={{ padding: '0px 16px 0px 16px' }}>
                                <Typography.Text className='admin-text' style={{ fontSize: 18 }}>Административная панель </Typography.Text>
                                <Tooltip
                                    title={
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Button type="text" onClick={() => {
                                                navigate('/admin/profile')
                                            }}
                                            >
                                                Профиль
                                            </Button>

                                            <Button type="text" onClick={() => {
                                                logout()
                                                navigate('/admin/login')
                                            }}
                                            >
                                                Выйти
                                            </Button>
                                        </div>
                                    }
                                >
                                    <Space style={{ cursor: 'pointer' }}>
                                        <Avatar
                                            className='user-avatar'
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            size='default'

                                        >
                                        </Avatar>

                                        <Typography.Text className='user-profile-text' style={{ fontSize: 14 }}>{user.email ? user.email : 'User'}</Typography.Text>
                                    </Space>
                                </Tooltip>
                                <MenuOutlined className="admin-menu-icon" style={{ color: '#fff', cursor: 'pointer' }} onClick={showDrawer} />
                                <Drawer
                                    title="Меню"
                                    placement="right"
                                    closable
                                    onClose={onClose}
                                    open={visible}
                                >
                                    <Menu
                                        theme="light"
                                        defaultSelectedKeys={['1']}
                                        selectedKeys={[location.pathname]}
                                        mode="inline"
                                        items={items}
                                        onClick={handleMenuClick}
                                    />
                                </Drawer>
                            </Flex>
                        </Header >
                        <Content
                            style={{
                                margin: '16px 16px 0',
                            }}
                        >
                            <div
                                style={{
                                    padding: 24,
                                    minHeight: 360,
                                    background: '#141414',
                                    borderRadius: 8,
                                }}
                            >
                                <Outlet />
                            </div>
                        </Content>
                    </Layout>
                </Layout >
            </ConfigProvider>
        </ProtectedComponent>
    );
};
export default AdminPage;