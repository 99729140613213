import React, { useEffect, useState, useRef } from 'react'
import { Form, Modal, Input, Button, Skeleton } from 'antd';
import UsersService from '../../Services/UsersService';
import Cookies from 'js-cookie';

const useResetFormOnCloseModal = ({ form, open, mode, user }) => {

    const prevOpenRef = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {

        if (!open && prevOpen) {
            form.resetFields();
        }

        if (open && mode === 'edit') {
            form.setFieldsValue(user);
        }

    }, [form, prevOpen, open, mode]);
};

export const ModalForm = ({ open, onCancel, loadingCard, setUsers, mode, user }) => {

    const [form] = Form.useForm();

    useResetFormOnCloseModal({
        form,
        open,
        mode,
        user
    });

    const onOk = () => {
        form.submit();
    };

    const [loading, setLoading] = useState(false); // Состояние загрузки

    // const storedToken = localStorage.getItem('authToken');

    const storedToken = Cookies.get('accessToken');

    const onFinish = (values) => {
        if (mode === 'create') UsersService.createUser(storedToken, values, setLoading, setUsers, onCancel)
        if (mode === 'edit') UsersService.updateUser(storedToken, user.id, values, setLoading, setUsers, onCancel)
    };

    return (
        <Modal
            title={mode === 'create' ? "Добавление пользователя" : 'Редактирование пользователя'}
            open={open}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Закрыть
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onOk}>
                    ОК
                </Button>,
            ]}
            onCancel={onCancel}
        >
            <Skeleton active={loadingCard} loading={loadingCard}>
                <Form form={form} layout="vertical" name="userForm" onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label="Имя пользователя"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, введите email!',
                            },
                            {
                                type: 'email',
                                message: 'Email введён некорректно'
                            },
                        ]}
                        hasFeedback
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, ведите пароль!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password allowClear />
                    </Form.Item>

                </Form>
            </Skeleton>
        </Modal >
    );
};

