import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Tooltip, Popconfirm, Space, Input, Select } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';


const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


export const getColumnSearchProps = (dataIndex, placeholder, searchInput, handleSearch, handleReset, setFilteredInfo, filteredInfo) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={searchInput}
                placeholder={placeholder}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Поиск
                </Button>
                <Button
                    onClick={() => {
                        clearFilters && handleReset(clearFilters)
                        confirm({
                            closeDropdown: true,
                        });
                    }}
                    size="small" style={{ width: 90 }}
                >
                    Сбросить
                </Button>

            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#faad14' : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => {
        if (record[dataIndex] == null) {
            return false;
        }
        return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
    },
    filteredValue: filteredInfo.title || null,
    onFilterDropdownOpenChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },
    // render: text =>
    //     filteredInfo[dataIndex] ? (
    //         <Highlighter
    //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //             searchWords={[filteredInfo[dataIndex]]}
    //             autoEscape
    //             textToHighlight={text ? text.toString() : ''}
    //         />
    //     ) : (
    //         text
    //     ),
});


export const getColumnMultiSelectProps = (dataIndex, nameFilter, options, handleSelectChange, filteredInfo, searchInput, handleReset) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }}>
            <Select
                ref={searchInput}
                mode="multiple"
                allowClear
                style={{ display: 'flex', flexDirection: 'column', width: '188px', marginBottom: 8 }}
                placeholder={nameFilter}
                value={selectedKeys}
                onChange={value => {
                    setSelectedKeys(value.length ? value : []);
                    handleSelectChange(value);
                }}
                options={options}
                filterOption={filterOption}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => {
                        confirm()
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Поиск
                </Button>
                <Button
                    onClick={() => {
                        clearFilters && handleReset(clearFilters)
                        handleSelectChange([]);
                        close();
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сбросить
                </Button>
            </Space>
        </div>
    ),
    onFilter: (value, record) =>
        Array.isArray(record[dataIndex]?.data) && record[dataIndex].data.some(section =>
            value.includes(`${section.type} #${section.id} (${section.tag})`)
        ),
    filteredValue: filteredInfo.sections || null,
    onFilterDropdownOpenChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.current?.focus(), 100);
        }
    },
});

