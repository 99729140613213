import React from 'react'
import { Modal, Skeleton, Row, Col, Typography } from 'antd';

const UserCard = ({ open, onCancel, loading, user }) => {

    const { Text } = Typography

    return (
        <Modal
            title='Карточка пользователя'
            open={open}
            onOk={onCancel}
            onCancel={onCancel}
            footer={(_, { OkBtn }) => (
                <>
                    <OkBtn />
                </>
            )}
        >
            <Skeleton active={loading} loading={loading}>
                <div >
                    <Row gutter={[24, 24]}>
                        <Col >
                            <p style={{ margin: 0 }}><Text strong>ID пользователя: </Text>{`${user.id}`}</p>
                        </Col>

                        <Col >
                            <p style={{ margin: 0 }}><Text strong>Имя пользователя: </Text>{`${user.name}`}</p>
                        </Col>

                        <Col >
                            <p style={{ margin: 0 }}><Text strong>Emai пользователя: </Text>{`${user.email}`}</p>
                        </Col>

                        <Col >
                            <p style={{ margin: 0 }}><Text strong>Дата создания пользователя: </Text>{`${user.created_at}`}</p>
                        </Col>

                        <Col >
                            <p style={{ margin: 0 }}><Text strong>Дата обновления пользователя: </Text>{`${user.updated_at}`}</p>
                        </Col>

                    </Row>

                </div >
            </Skeleton>
        </Modal>
    )
}

export default UserCard