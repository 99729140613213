import React, { useEffect, useState, useRef } from 'react';
import { Form, Modal, Button, Skeleton, Select, Switch, DatePicker } from 'antd';
import Cookies from 'js-cookie';
import ArticlesService from '../../Services/ArticlesService';
import FilesDrawer from './FilesDrawer';
import { getFieldsForSection, makeField, renderSelectedComponents } from '../../Helpers/RenderHelper';
import dayjs from 'dayjs'
import { replaceTags } from '../../Services/replaceTags';
import { sortSections, createSectionsOptions, createSectionMapping } from '../../Helpers/sectionsUtils';
import CONFIG from '../../../config';

const useResetFormOnCloseModal = ({ form, open, mode, article, setSelectedSections, setSelectedComponents, editorRef, setTextValue }) => {

    const prevOpenRef = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {

        if (!open && prevOpen) {
            form.resetFields();
            setSelectedSections([]);
            setSelectedComponents([]);
            setTextValue('')
        }

        if (open && mode === 'edit' && article) {

            const replacedText = replaceTags(article.text, article.images.data);

            setTextValue(replacedText)

            form.setFieldsValue({
                ...article,
                sections: article?.sections?.data.map(section => `${section.type} #${section.id} (${section.tag})`),
                image: `${article?.image?.id}`,
                images: article?.images?.data.map(image => `${image.id}`),
                published_at: article?.published_at ? dayjs(article?.published_at) : null
                // is_published: article?.published_at === null ? false : true
            });
            setSelectedSections(article?.sections?.data.map(section => `${section.type} #${section.id} (${section.tag})`))

        }
    }, [form, prevOpen, open, mode, article, setSelectedSections, setSelectedComponents]);


};

export const ModalForm = ({ open, onCancel, loadingCard, setArticles, mode, article, page, pageSize, setTotal, sections, queryString }) => {

    const [form] = Form.useForm();

    const [files, setFiles] = useState([]);

    const [loading, setLoading] = useState(false);
    const storedToken = Cookies.get('accessToken');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedSections, setSelectedSections] = useState([]);
    const [selectedComponents, setSelectedComponents] = useState([]);

    const [textValue, setTextValue] = useState('');

    const handleSelectChange = (selectedSections) => {
        setSelectedSections(selectedSections);
    };

    const handleFileChange = fileId => {
        form.setFieldsValue({ image: fileId }); // Обновляем значение поля image в форме при изменении выбранного файла
    };

    useEffect(() => {

        renderSelectedComponents(selectedSections, setSelectedComponents);

    }, [selectedSections]);

    const sortedSections = sortSections(sections);
    const sectionsOptions = createSectionsOptions(sortedSections);
    const sectionMapping = createSectionMapping(sortedSections);

    const options = Object.keys(sectionMapping).map(key => ({
        value: key,
        label: sectionMapping[key],
    }));

    const onFinish = (values, sectionsOptions) => {

        // Функция для замены undefined на null
        const replaceUndefinedWithNull = (obj) => {
            const keysToCheck = ['sections', 'images']; // Добавляем 'images' в массив keysToCheck
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    if (!keysToCheck.includes(key)) {
                        if (obj[key] === undefined || obj[key] === '') { // Если значение undefined или пустая строка, заменяем на null
                            obj[key] = null;
                        }
                    } else if (Array.isArray(obj[key]) && obj[key].length === 0) { // Если значение массив и пустой, оставляем его пустым
                        obj[key] = [];
                    }
                }
            }
            return obj;
        };

        // Замена undefined на null в переданных значениях
        let updatedValues = { ...values, text: textValue };

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = updatedValues.text;
        const imgs = tempDiv.querySelectorAll('img');
        const links = tempDiv.querySelectorAll('a');
        const images = [];

        // Регулярное выражение для фильтрации ссылок
        const regex = new RegExp(`^${CONFIG.PUBLIC_API.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\/storage\\/.*?\\/([\\w-]+)(\\.[\\w-]+)?$`);

        // Обработка img тегов
        imgs.forEach((img) => {
            const src = img.getAttribute('src');
            if (src && regex.test(src)) {
                const match = src.match(regex);
                if (match) {
                    const imageId = match[1]; // Используем первую группу (идентификатор)
                    img.removeAttribute('src');
                    img.setAttribute('id', imageId);
                    images.push(imageId); // Добавляем id в массив images
                }
            }
        });

        // Обработка тегов a
        links.forEach((link) => {
            const href = link.getAttribute('href');
            if (href && regex.test(href)) {
                const match = href.match(regex);
                if (match) {
                    const fileId = match[1];
                    link.removeAttribute('href'); // Удаление атрибута href
                    link.setAttribute('id', fileId); // Установка атрибута id
                    images.push(fileId);
                }
            }
        });



        // Преобразование секций в соответствующие числа
        if (updatedValues.sections) {
            updatedValues.sections = updatedValues.sections.map(section => sectionsOptions[section]);
        } else {
            updatedValues.sections = null;
        }

        // Обновление текста с удаленными src и добавленными id
        updatedValues.text = tempDiv.innerHTML;

        // Добавление массива id изображений в объект updatedValues
        updatedValues.images = images;

        updatedValues = replaceUndefinedWithNull(updatedValues)

        if (mode === 'create') {
            ArticlesService.createArticle(storedToken, updatedValues, setLoading, setArticles, onCancel, queryString, setTotal);
        }
        if (mode === 'edit') {
            ArticlesService.updateArticle(storedToken, article.id, updatedValues, setLoading, setArticles, onCancel, queryString, setTotal);
        }
    };

    const editorRef = useRef(null);

    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const onClose = () => {
        setOpenDrawer(false);
    };

    useResetFormOnCloseModal({
        form,
        open,
        mode,
        article,
        setSelectedSections,
        setSelectedComponents,
        renderSelectedComponents,
        editorRef, setTextValue
    });

    const onOk = () => {
        form.submit();
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title={mode === 'create' ? "Добавление статьи" : 'Редактирование статьи'}
            open={open}
            width={'80vw'}
            style={{ position: 'relative', overflow: 'hidden' }}
            footer={[
                <div key="footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={showDrawer}>Файлы</Button>
                    <div>
                        <Button key="back" onClick={onCancel} style={{ marginRight: 8 }}>
                            Закрыть
                        </Button>
                        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
                            ОК
                        </Button>
                    </div>
                </div>
            ]}
            onCancel={onCancel}
            maskClosable={false}
        >
            <Skeleton active={loadingCard} loading={loadingCard}>
                <Form form={form} layout="horizontal" name="userForm" onFinish={(values => onFinish(values, sectionsOptions))}>

                    <Form.Item
                        name="sections"
                        label="Типы секций"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        labelCol={{ span: 24 }}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Выберите тип"
                            filterOption={filterOption}
                            onChange={handleSelectChange}
                            options={options}
                        />
                    </Form.Item>

                    {
                        selectedComponents.map(field =>
                            makeField(field, editorRef, textValue, setTextValue, storedToken, openDrawer, article, handleFileChange, setFiles, files))
                    }

                    {/* <Form.Item
                        name="is_published"
                        valuePropName="checked"
                        label="Опубликовать"

                    >
                        <Switch />
                    </Form.Item> */}

                    <Form.Item
                        name="published_at"
                        label="Дата публикации"

                    >
                        <DatePicker
                            format={{
                                format: 'DD.MM.YYYY HH:mm:ss',
                                type: 'mask',
                            }}
                            showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>

                    <FilesDrawer
                        open={openDrawer}
                        onClose={onClose}
                        files={files}
                        setFiles={setFiles}
                    />

                </Form>
            </Skeleton>
        </Modal >
    );
};

export default ModalForm;
