import React, { useState, useEffect } from 'react'
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import SectionRenderer from '../Services/SectionRenderer';
import PublicSectionsService from '../Services/PublicSectionsService';

const TV = () => {

  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(false)

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {

    const tag = location.pathname.slice(1)

    PublicSectionsService.getPublicSections(tag, setLoading, setSections)

  }, []);

  return (
    <>
      {SectionRenderer(sections, navigate)}
    </>
  )
}

export default TV