import React from 'react'
import { AuthData } from './AuthWrapper'
import { Navigate } from 'react-router-dom';
import CircleLoader from "react-spinners/CircleLoader";
import Cookies from 'js-cookie';

const ProtectedComponent = ({ children }) => {

    const { loading } = AuthData()

    const token = Cookies.get('accessToken')

    if (loading) return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircleLoader color="#36d7b7" loading={loading} />
    </div>

    return token ? children : <Navigate to='/admin/login' />
}

export default ProtectedComponent