import React, { useEffect, useState } from 'react'
import { Table, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { ModalForm } from './Modals/SectionModal';
import Cookies from 'js-cookie';
import SectionsService from '../Services/SectionsService';


const Sections = () => {

  const [loading, setLoading] = useState(false)

  const [sections, setSections] = useState([]);

  const storedToken = Cookies.get('accessToken');

  const [open, setOpen] = useState(false);

  const loadingCard = false;

  const [section, setSection] = useState({})

  const [modeModal, setModeModal] = useState('create')

  const showSectionModal = () => {
    setOpen(true);
  };

  const hideSectionModal = () => {
    setOpen(false);
  };

  useEffect(() => {

    if (storedToken) {
      SectionsService.getSections(storedToken, setLoading, setSections)
    }
  }, [storedToken]);


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Тэг',
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => value ? (<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>) : ''
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: value => value ? (<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>) : ''
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      render: (_, record) => (
        <Space size='small'>

          <div>
            <Tooltip title="Редактировать" placement="bottom">

              <EditOutlined
                style={{ color: '#1890ff', fontSize: '15px', cursor: 'pointer' }}
                onClick={() => {
                  setSection(record)
                  setModeModal('edit')
                  showSectionModal()
                }}
              />

            </Tooltip>
          </div>
        </Space>
      ),
    },
  ];

  return (
    <div>

      <Table
        dataSource={sections.map(item => ({ ...item, key: item.id }))}
        columns={columns}
        style={{ marginTop: 8 }}
        loading={loading}
        scroll={{ 
          x: 768, 
          // y: 'calc(100vh - 25em)' 
        }}
      />

      <ModalForm
        open={open}
        onCancel={hideSectionModal}
        loadingCard={loadingCard}
        setSections={setSections}
        mode={modeModal}
        section={section}
      />

    </div>
  )

}

export default Sections