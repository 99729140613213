export const replaceTags = (text, imagesData) => {
    let updatedText = text;

    // Обработка тегов img
    imagesData.forEach(imageData => {
        const imgTagRegex = new RegExp(`<img[^>]*id="${imageData.id}"[^>]*>`, 'g');
        const imgTagMatches = updatedText.match(imgTagRegex);

        if (imgTagMatches) {
            imgTagMatches.forEach(imgTag => {

                const imgAttributes = imgTag.match(/(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/g);

                const additionalAttributes = imgAttributes
                    .filter(attribute => {
                        const [key, value] = attribute.split('=');  // Разделяем ключ-значение
                        return value !== undefined && value !== null && value !== '' && key !== 'href' && key !== 'title';  // Удаляем undefined, null, пустые строки и исключаем href, title
                    })
                    .map(attribute => attribute.trim())  // Удаляем лишние пробелы из атрибутов
                    .join(' ');  // Объединяем атрибуты обратно в строку
                const imgTagWithAllAttributes = `<img src="${imageData.url}" alt="${imageData.name}" id="${imageData.id}" title="${imageData.name}" ${additionalAttributes} />`;

                updatedText = updatedText.replace(imgTag, imgTagWithAllAttributes);
            });
        }
    });

    // Обработка тегов a
    imagesData.forEach(imageData => {
        const anchorTagRegex = new RegExp(`<a[^>]*id="${imageData.id}"[^>]*>[^<]*<\/a>`, 'g');
        const anchorTagMatches = updatedText.match(anchorTagRegex);

        if (anchorTagMatches) {
            anchorTagMatches.forEach(anchorTag => {

                const anchorAttributes = anchorTag.match(/(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/g);

                const additionalAttributes = anchorAttributes
                    .filter(attribute => {
                        const [key, value] = attribute.split('=');  // Разделяем ключ-значение
                        return value !== undefined && value !== null && value !== '' && key !== 'href' && key !== 'title';  // Удаляем undefined, null, пустые строки и исключаем href, title
                    })
                    .map(attribute => attribute.trim())  // Удаляем лишние пробелы из атрибутов
                    .join(' ');  // Объединяем атрибуты обратно в строку

                const anchorTagWithAllAttributes = `<a href="${imageData.url}" download="${imageData.name}" id="${imageData.id}" title="${imageData.name}"  ${additionalAttributes}>${imageData.name}</a>`;
                updatedText = updatedText.replace(anchorTag, anchorTagWithAllAttributes);
            });
        }
    });

    return updatedText;
};
