import { message } from 'antd';
import CONFIG from '../../config';

const articlesApi = CONFIG.ARTICLES_API

const ArticlesService = {

    getArticles: (token, queryString, setLoading, setArticles, setTotal) => {
        setLoading(true);
        fetch(`${articlesApi}?${queryString}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при получении списка статей');
                }
                return response.json();
            })
            .then(data => {
                setTotal(data.meta.pagination.total); // Обновляем общее количество статей
                setArticles(data.data); //  // Вызываем функцию обратного вызова для обновления состояния статей
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },
    createArticle: (token, values, setLoading, setArticles, onCancel, queryString, setTotal) => {

        setLoading(true);
        fetch(`${articlesApi}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(values)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка создания новой статьи');
                }
                return response.json();
            })
            .then(data => {
                message.success(`Новая статья успешно добавлена!`);
                ArticlesService.getArticles(token, queryString, setLoading, setArticles, setTotal);
                onCancel()
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    updateArticle: (token, id, values, setLoading, setArticles, onCancel, queryString, setTotal) => {
        setLoading(true);

        fetch(`${articlesApi}/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({ ...values, '_method': 'put' })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при редактировании статьи');
                }
                return response.json();
            })
            .then(data => {
                message.success(`Статья успешно отредактирована!`);
                ArticlesService.getArticles(token, queryString, setLoading, setArticles, setTotal);
                onCancel()
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    deleteArticle: (token, id, setLoading, setArticles, queryString, setTotal) => {
        setLoading(true);
        fetch(`${articlesApi}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при удалении статьи');
                }

                return response;
            })
            .then(() => {
                message.success(`Статья успешно удалена!`);
                ArticlesService.getArticles(token, queryString, setLoading, setArticles, setTotal);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

};

export default ArticlesService;