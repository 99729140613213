import React from 'react';
import { Button, Form, Input, Checkbox, Card } from 'antd';
import { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../Auth/AuthWrapper"

const Login = () => {

    const navigate = useNavigate();
    const { login, loading } = AuthData();
    const [formData, setFormData] = useReducer((formData, newItem) => ({ ...formData, ...newItem }), { email: "", password: "", remember: true });
    const [errorMessage, setErrorMessage] = useState(null);

    // useEffect(() => {
    //     console.log('Login mounted')
    //     if (user.isAuthenticated) {
    //         navigate('/admin/profile');
    //     }
    // }, [user, navigate]);

    const doLogin = async () => {
        try {
            await login(formData.email, formData.password, formData.remember);
            navigate('/admin/profile');
        } catch (error) {
            setErrorMessage(error);
        }
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (

        <div
            className='main prlx'
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <div className='prlx-l prlx-l-4 bg-img'></div>
            <div className='prlx-l prlx-l-3 bg-stars'></div>
            <div className='prlx-l prlx-l-2 bg-mstars'></div>
            <div className='prlx-l prlx-l-1 bg-clouds'></div>

            <Card title='Вход'
                style={{
                    width: '30%',
                    minWidth: 300,
                    // position: 'absolute',
                    zIndex: 990,
                }}
                styles={{
                    body: {
                        padding: '24px 24px 0px 24px'
                    }
                }}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, введите email!',
                            },
                            {
                                type: 'email',
                                message: 'Email введён некорректно'
                            },
                        ]}
                        hasFeedback
                    >
                        <Input value={formData.email} onChange={(e) => setFormData({ email: e.target.value })} allowClear />
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, ведите пароль!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password value={formData.password} onChange={(e) => setFormData({ password: e.target.value })} allowClear />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox value={formData.remember} onChange={(e) => setFormData({ remember: e.target.value })}>Запомнить меня</Checkbox>
                    </Form.Item>

                    <Form.Item
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            onClick={doLogin}
                            loading={loading}
                        >
                            Войти
                        </Button>
                    </Form.Item>
                    <Form.Item
                    >
                        <Button
                            type="link"
                            htmlType="submit"
                            block
                            onClick={() => navigate('/')}
                        >
                            На главную
                        </Button>
                    </Form.Item>
                </Form>
                {errorMessage ?
                    <div className="error">{errorMessage}</div>
                    : null}
            </Card>

        </div>

    );
};
export default Login;