import React, { useState, useEffect } from 'react'
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import SectionRenderer from '../Services/SectionRenderer';
import PublicSectionsService from '../Services/PublicSectionsService';

const AdditionalServices = () => {

    const [sections, setSections] = useState([])

    const [loadingSections, setLoadingSections] = useState(false)

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {

        const tag = location.pathname.slice(1)

        PublicSectionsService.getPublicSections(tag, setLoadingSections, setSections)

    }, []);


    return (
        <>
            {SectionRenderer(sections, navigate)}
        </>
    )
}

export default AdditionalServices