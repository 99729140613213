import React, { useEffect } from 'react';
import { Result, Button } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import Profile from './Pages/Admin App/Components/Profile';
import Users from './Pages/Admin App/Components/Users';
import Login from './Pages/Auth/Login';
import Articles from './Pages/Admin App/Components/Articles';
import Sections from './Pages/Admin App/Components/Sections';
import AdminPage from './Pages/Admin App/AdminPage';
import MainPage from './Pages/Main App/MainPage';
import CCTV from './Pages/Main App/Components/CCTV';
import Internet from './Pages/Main App/Components/Internet';
import OTVSNZ from './Pages/Main App/Components/OTVSNZ';
import TV from './Pages/Main App/Components/TV';
import Contacts from './Pages/Main App/Components/Contats';
import Home from './Pages/Main App/Components/Home';
import ArticlePage from './Pages/Main App/Components/ArticlePage';
import NotFound from './Pages/Main App/Components/NotFound';
import AdditionalServices from './Pages/Main App/Components/AdditionalServices';

const App = () => {

  // useEffect(() => {
  //   console.log('App mounted');
  // }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage />,
      children: [
        {
          path: "/",
          element: <Navigate to="home" />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "cctv",
          element: <CCTV />,
        },
        {
          path: "internet",
          element: <Internet />,
        },
        {
          path: "tv",
          element: <TV />,
        },
        {
          path: "articles/:slug",
          element: <ArticlePage />,
        },
        {
          path: "services",
          element: <AdditionalServices />,
        },
        {
          path: "otv",
          element: <OTVSNZ />,
        },
        {
          path: "*",
          element: <NotFound />
        },
      ],
    },
    {
      path: "/admin",
      element: <AdminPage />,
      children: [
        {
          path: "/admin",
          element: <Navigate to="/admin/users" />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "sections",
          element: <Sections />,
        },
        {
          path: "articles",
          element: <Articles />,
        },
        {
          path: "*",
          element: <Result
            status="404"
            title="404"
            subTitle="К сожалению, страница, которую Вы запрашиваете, не существует."
            extra={
              <Button
                type="primary"
                href={`/admin/profile`}
              >
                На главную
              </Button>
            }
          />
        },
      ]
      ,
    },
    {
      path: "/admin/login",
      element: <Login />,
    },
  ]);

  return <RouterProvider router={router} />

};
export default App;