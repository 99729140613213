export const sortSections = (sections) => {
    return [...sections].sort((a, b) => {
        if (a.tag !== b.tag) {
            const tagsOrder = ['home', 'internet', 'tv', 'cctv', 'services', 'otv', 'article'];
            const aIndex = tagsOrder.indexOf(a.tag);
            const bIndex = tagsOrder.indexOf(b.tag);
            return aIndex - bIndex;
        }
        return a.id - b.id;
    });
};

export const createSectionsOptions = (sections) => {
    return sections.reduce((acc, item) => {
        acc[`${item.type} #${item.id} (${item.tag})`] = item.id;
        return acc;
    }, {});
};

export const createSectionMapping = (sections) => {
    return sections.reduce((acc, section) => {
        const key = `${section.type} #${section.id} (${section.tag})`;
        let label = '';
        switch (section.type) {
            case 'news':
                label = 'Новости';
                break;
            case 'services':
                label = 'Сервисы';
                break;
            case 'information_portal':
                label = 'Информпортал';
                break;
            case 'carousel':
                label = 'Карусель';
                break;
            case 'text':
                label = 'Текст';
                break;
            case 'rubrics':
                label = 'Рубрики';
                break;
            case 'rates':
                label = 'Тарифные планы';
                break;
            default:
                label = section.type;
                break;
        }
        label += ` #${section.id} (${section.tag})`;
        acc[key] = label;
        return acc;
    }, {});
};