import { message } from 'antd';
import CONFIG from '../../config';

const usersApi = CONFIG.USERS_API

const UsersService = {

    getUsers: (token, setLoading, setUsers) => {
        setLoading(true);
        fetch(`${usersApi}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при получении списка пользователей');
                }
                return response.json();
            })
            .then(data => {
                setUsers(data.data);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    createUser: (token, values, setLoading, setUsers, onCancel) => {
        setLoading(true);
        fetch(`${usersApi}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(values)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка создания нового пользователя');
                }
                return response.json();
            })
            .then(data => {
                const userName = data.name;
                message.success(`Новый пользователь ${userName} успешно добавлен!`);
                UsersService.getUsers(token, setLoading, setUsers);
                onCancel()
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    updateUser: (token, id, values, setLoading, setUsers, onCancel) => {
        setLoading(true);
        fetch(`${usersApi}/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({ ...values, '_method': 'put' })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при редактировании пользователя');
                }
                return response.json();
            })
            .then(data => {
                const userName = data.name;
                message.success(`Пользователь ${userName} успешно отредактирован!`);
                UsersService.getUsers(token, setLoading, setUsers);
                onCancel()
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

    showUser: (token, id, setLoadingCard, setUser) => {
        setLoadingCard(true);
        fetch(`${usersApi}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка загрузки пользователя');
                }
                return response.json();
            })
            .then(data => {
                setUser(data)
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoadingCard(false));
    },

    deleteUser: (token, id, setLoading, setUsers) => {
        setLoading(true);
        fetch(`${usersApi}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка при удалении пользователя');
                }

                return response;
            })
            .then(() => {
                message.success(`Пользователь успешно удалён!`);
                UsersService.getUsers(token, setLoading, setUsers);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    },

};

export default UsersService;