import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Tooltip, Popconfirm, Space, Input, Select } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import ArticlesService from '../Services/ArticlesService';
import dayjs from 'dayjs';
import { ModalForm } from './Modals/ArticleModal';
import SectionsService from '../Services/SectionsService';
import { sortSections, createSectionsOptions, createSectionMapping } from '../Helpers/sectionsUtils';
import { getColumnMultiSelectProps, getColumnSearchProps } from '../Helpers/filtersUtils';

const Articles = () => {
    const [loading, setLoading] = useState(false);
    const [loadingSections, setLoadingSections] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [modeModal, setModeModal] = useState('create');
    const [articles, setArticles] = useState([]);
    const [sections, setSections] = useState([]);
    const [article, setArticle] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const sortedSections = sortSections(sections);
    const sectionsOptions = createSectionsOptions(sortedSections);
    const sectionMapping = createSectionMapping(sortedSections);

    const options = Object.keys(sectionMapping).map(key => ({
        value: key,
        label: sectionMapping[key],
    }));

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const storedToken = Cookies.get('accessToken');

    useEffect(() => {
        loadArticles();
    }, [page, pageSize, filteredInfo, searchText, sortedInfo]);

    const createQueryString = (params) => {
        return Object.keys(params)
            .map(key => {
                if (Array.isArray(params[key])) {
                    return params[key].map(value => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`).join('&');
                }
                return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
            })
            .join('&');
    };


    const selectedSections = filteredInfo.sections || [];
    const formattedSections = selectedSections.map(section => {
        // Разбиваем строку по пробелу и берем первый элемент, чтобы получить id секции
        const sectionId = section.split(' ')[1];
        return sectionId.match(/\d+/)[0];
    });

    const params = {
        include: 'sections',
        page,
        per_page: pageSize
    };

    if (searchText) {
        params.search = searchText;
    }

    if (formattedSections.length) {
        params.sections = formattedSections;
    }

    if (sortedInfo.order) {
        params.sort = [`["${sortedInfo.columnKey}", ${sortedInfo.order === 'ascend' ? false : true}]`];
    }

    const queryString = createQueryString(params);

    const loadArticles = () => {

        if (storedToken) {

            ArticlesService.getArticles(storedToken, queryString, setLoading, setArticles, setTotal);
            SectionsService.getSections(storedToken, setLoadingSections, setSections);
        }
    };


    // const handleArticlesLoaded = (data) => {
    //     setTotal(data.meta.pagination.total); // Обновляем общее количество статей
    //     setArticles(data.data); // Устанавливаем сами статьи
    // };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setPage(1);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({})
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter)

    };

    const handleSelectChange = (selectedKeys) => {
        setFilteredInfo(prev => ({
            ...prev,
            sections: selectedKeys.length ? selectedKeys : null,
        }));
    };

    const multiSelectProps = getColumnMultiSelectProps('sections', 'Поиск по секциям', options, handleSelectChange, filteredInfo, searchInput, handleReset);
    const searchProps = getColumnSearchProps('title', 'Поиск по заголовку', searchInput, handleSearch, handleReset, setFilteredInfo, filteredInfo);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '5em',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
        },
        {
            title: 'Секции',
            width: '12em',
            dataIndex: 'sections',
            key: 'sections',
            ...multiSelectProps,
            render: (sections) => {

                return <div>
                    {sections.data.map(section => (
                        <div key={section?.id}>
                            {`${section?.type} #${section?.id} (${section?.tag})`}
                        </div>
                    ))}
                </div>
            },
        },
        {
            title: 'Заголовок',
            dataIndex: 'title',
            key: 'title',
            width: '20rem',
            ...searchProps
        },
        {
            title: 'Дата публикации',
            width: '11em',
            dataIndex: 'published_at',
            key: 'published_at',
            render: value => value ? (<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>) : '',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'published_at' ? sortedInfo.order : null,
        },
        {
            title: 'Дата создания',
            width: '11em',
            dataIndex: 'created_at',
            key: 'created_at',
            render: value => value ? (<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>) : '',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
        },
        {
            title: 'Дата обновления',
            width: '11em',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: value => value ? (<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>) : '',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'updated_at' ? sortedInfo.order : null,
        },
        {
            title: 'Действие',
            width: '7em',
            dataIndex: 'action',
            align: 'center',
            key: 'action',
            render: (_, record) => (
                <Space size='small'>
                    <div>
                        <Tooltip title="Редактировать" placement="bottom">
                            <EditOutlined
                                style={{ color: '#1890ff', fontSize: '15px', cursor: 'pointer' }}
                                onClick={() => {
                                    setArticle(record);
                                    setModeModal('edit');
                                    showModal();
                                }}
                            />
                        </Tooltip>
                    </div>
                    <Popconfirm
                        title="Вы уверены?"
                        onConfirm={() => ArticlesService.deleteArticle(storedToken, record.id, setLoading, setArticles, queryString, setTotal)}
                        okButtonProps={{
                            loading: loading,
                        }}
                        okText="Удалить"
                        cancelText="Отмена">
                        <div>
                            <Tooltip title="Удалить" placement="bottom">
                                <DeleteOutlined
                                    style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }}
                                />
                            </Tooltip>
                        </div>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Button
                htmlType="button"
                onClick={() => {
                    setModeModal('create');
                    showModal();
                    setArticle({});
                }}
            >
                Добавить статью
            </Button>
            <Button
                onClick={clearAll}
                style={{ marginLeft: 8 }}
            >
                Сбросить фильтры
            </Button>
            <Table
                dataSource={Array.isArray(articles) ? articles.map(item => ({ ...item, key: item.id })) : []}
                columns={columns}
                style={{ marginTop: 8 }}
                loading={loading}
                scroll={{ x: 768, y: 'calc(100vh - 25em)' }}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    onChange: handlePageChange,
                    onShowSizeChange: handlePageSizeChange,
                    showSizeChanger: true,
                    showTotal: (total) => `Всего: ${total} статей`,
                    pageSizeOptions: ['10', '20', '30', '50', `${total} `],
                }}
                onChange={handleChange}
            />
            <ModalForm
                open={isModalOpen}
                onCancel={handleCancel}
                loadingCard={loadingCard}
                setArticles={setArticles}
                mode={modeModal}
                article={article}
                page={page}
                pageSize={pageSize}
                setTotal={setTotal}
                sections={sections}
                queryString={queryString}
            />
        </div>
    );
};

export default Articles;
