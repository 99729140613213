import React, { useState, useEffect } from 'react'
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import SectionRenderer from '../Services/SectionRenderer';
import PublicSectionsService from '../Services/PublicSectionsService';
import otvLogo from '../../../Assets/img/otv/logo2.png'
import youtube from '../../../Assets/img/otv/youtube.png'
import rutube from '../../../Assets/img/otv/rutube.png'
import telegram from '../../../Assets/img/otv/telegram.png'

const OTVSNZ = () => {

  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(false)

  const location = useLocation();

  const navigate = useNavigate()

  useEffect(() => {

    const tag = location.pathname.slice(1)

    PublicSectionsService.getPublicSections(tag, setLoading, setSections)


  }, []);



  return (
    <>
      <section className="s-otv blue">
        <div className="container">
          <div className="v-flex">
            <div className="w-1-2">
              <div className="v-flex-col">
                <div className="otv-cart"><div><big>168</big><div>часов ежедневно<br />эфирного времени</div></div></div>
                <div className="otv-cart"><div><big>31</big><div>канал ОТВ</div></div></div>
                <div className="otv-cart"><div><big>2005</big><div>год регистрации канала</div></div></div>
                <div className="otv-cart"><div><big>2009</big><div>год начала вещания из собственной телестудии</div></div></div>
              </div>
              <div className="text-center w-1-1 otv-social">
                <h5>МЫ В СОЦСЕТЯХ:</h5>
                <a href="https://www.youtube.com/@snzotv"><img src={youtube} /></a>
                <a href="https://rutube.ru/channel/32578246/"><img src={rutube} /></a>
                <a href="https://t.me/otvsnz"><img src={telegram} /></a>
              </div>
            </div>
            <div className="w-1-2 otv-about">

              <div className="article">
                <img src={otvLogo} />
                <h3>&laquo;ОБЩЕСТВЕННОЕ ТЕЛЕВИДЕНИЕ &ndash; СНЕЖИНСК&raquo;</h3>
                <p>Структурное подразделение ООО  &laquo;Вега-Интернет&raquo;.</p>
                <p>Редакция телевидения осуществляет круглосуточное кабельное вещание на 31 канале. Ежедневно в 19:00 в эфир выходит новый ввыпуск и затем транслируется в повторах 22 раза, чтобы зрители могли выбрать для себя удобное время для просмотра.</p>
                <p>Журналисты занимаются производством собственных информационно-аналитических, культурно-просветительских, спортивных программ в разных жанрах и форматах.</p>
              </div>
              <div className="text-right"><a className="btn " onClick={() => navigate('/articles/uslugi-otv')} style={{ cursor: 'pointer' }}>Показать ещё</a></div>
            </div>
          </div>

        </div>
      </section>


      {SectionRenderer(sections, navigate)}
    </>
  )
}

export default OTVSNZ